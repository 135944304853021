import React from 'react';
import './ScrollingBanner.css';

const ScrollingBanner = () => {
  const message = "Offre spécial ÉTÉ : La One Page à 295€ seulement !";

  return (
    <div className="w-full overflow-hidden">
      <div className="scrollerBandeau whitespace-nowrap py-2">
        <div className="inline-block">
          {[...Array(6)].map((_, index) => (
            <span key={index} className="inline-flex items-center px-4">
              <i className="fa-solid fa-bullhorn text-white text-xl mr-2"></i>
              <span className="text-white font-medium">{message}</span>
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ScrollingBanner;