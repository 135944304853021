// burgerActions
export const toggleBurger = () => ({
    type: 'TOGGLE_BURGER',
  });

// burgerReducer
const initialState = {
    isChecked: false,
  };
  
  const burgerReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'TOGGLE_BURGER':
        return {
          ...state,
          isChecked: !state.isChecked,
        };
      default:
        return state;
    }
  };
  
  export default burgerReducer;