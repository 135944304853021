// store.js
import { configureStore } from '@reduxjs/toolkit';
import burgerReducer from './burgerActions';

const store = configureStore({
    reducer: {
      burger: burgerReducer,
    },
  });

export default store;
