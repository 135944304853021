import React from 'react';
import { connect } from 'react-redux';
import { toggleBurger } from '../../Redux/burgerActions';

import './MenuBanner.css';

function MenuBanner({ isBannerVisible, toggleBurger, onNavLinkClick }) {
  const handleNavLinkClick = () => {
    toggleBurger(); 
    if (onNavLinkClick) {
      onNavLinkClick();
    }
  };

  return isBannerVisible ? (
    <div className="MenuBanner show">
      <div className="List-title-pages">
      <ul>
          <li><a href="#about" onClick={handleNavLinkClick}>L'AGENCE</a></li>
          <li><a href="#works" onClick={handleNavLinkClick}>NOS RÉALISATIONS</a></li>
          <li><a href="#pricing" onClick={handleNavLinkClick}>TARIFS</a></li>
          <li><a href="#contact" onClick={handleNavLinkClick}>CONTACT</a></li>
        </ul>
      </div>
      <div className="contact-details">
        <ul>
          <h3><strong>Une question ?</strong></h3>
          <span></span>
          <li>+33(0)6 27 23 59 76</li>
          <li>atelierdecember@gmail.com</li>
        </ul>
      </div>
    </div>
  ) : null;
}

const mapStateToProps = (state) => ({
  isBannerVisible: state.burger.isChecked,
});

const mapDispatchToProps = {
  toggleBurger,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuBanner);
