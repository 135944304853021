import React from 'react';
import { connect } from 'react-redux';
import { toggleBurger } from '../../../Redux/burgerActions';
import './Burger.css';

function Burger({ isChecked, toggleBurger }) {
  return (
    <div>
      <label className="burger" htmlFor="burger">
        <input type="checkbox" id="burger" checked={isChecked} onChange={toggleBurger} />
        <span></span>
        <span></span>
        <span></span>
      </label>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isChecked: state.burger.isChecked,
});

const mapDispatchToProps = {
  toggleBurger,
};

export default connect(mapStateToProps, mapDispatchToProps)(Burger);
