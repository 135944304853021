import React from 'react';
import './WelcomeBanner.css';

function WelcomeBanner() {
  return (
    <section className="WelcomeBanner relative">
      <div
        className="absolute inset-0 m-auto max-w-xs h-[357px] blur-[118px] sm:max-w-md md:max-w-lg"
        style={{ background: 'linear-gradient(106.89deg, rgba(255, 255, 255, 0.2) 0%, rgba(192, 192, 192, 0.3) 100%)' }}
      ></div>
      <div className="relative z-7 max-w-screen-xl mx-auto px-4 py-28 md:px-8">
        <div className="space-y-5 max-w-4xl mx-auto text-center">
          <h2 className="text-4xl text-[#f4f3f3] font-extrabold mx-auto md:text-5xl">
            Un site pro, un design qui vous ressemble et un tarif adapté à votre budget.
          </h2>
          <p className="max-w-2xl mx-auto text-[#f4f3f3]">
            Transformez les visiteurs en clients.
          </p>
          <div className="justify-center items-center gap-x-3 sm:flex">
            <a href="#contact">
              <button className="flex items-center justify-center gap-x-2 py-2.5 px-4 mt-3 w-full text-sm text-[--slate-800] font-bold bg-[#f4f3f3] hover:bg-custom-gradient hover:text-[#f4f3f3] duration-150 rounded-lg sm:mt-0 sm:w-auto">
                Contact
              </button>
            </a>
            <a href="https://calendly.com/atelierdecember/appel-de-decouverte-pour-la-creation-de-site-web">
              <button className="flex items-center justify-center gap-x-2 py-2.5 px-4 mt-3 w-full text-sm text-[#f4f3f3] font-bold bg-custom-gradient hover:text-[#f4f3f3]  duration-150 rounded-lg sm:mt-0 sm:w-auto">
                Prendre rendez-vous gratuitement
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                  <path
                    fillRule="evenodd"
                    d="M2 10a.75.75 0 01.75-.75h12.59l-2.1-1.95a.75.75 0 111.02-1.1l3.5 3.25a.75.75 0 010 1.1l-3.5 3.25a.75.75 0 11-1.02-1.1l2.1-1.95H2.75A.75.75 0 012 10z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WelcomeBanner;
