import React, { useState, useEffect } from 'react';
import './Loader.css';
import Logo from '../../img/logoNoBg.png';

const Loader = () => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    visible && (
      <div className="loader-container">
        <div className="loader-content">
          <img className="loader-image" src={Logo} alt="logo AD" />
        </div>
      </div>
    )
  );
};

export default Loader;
