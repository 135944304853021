import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import Works from './pages/Works/Works';
import Pricing from './pages/Pricing/Pricing';
import Loader from './components/Loader/Loader';
import ScrollButton from './components/ScrollButton/ScrollButton';


function App() {
  return (
    <>
    <Loader />
    <div>
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/works" element={<Works />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/contact" element={<Contact />} />   
        </Routes>
        <ScrollButton />
    </div>
    </>
  );
}

export default App;
