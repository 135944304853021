import React from 'react';
import './Contact.css';

function Contact() {
  return (
    <section id="contact" className="py-14 Contact relative">
      <div className="max-w-screen-xl mx-auto px-4 md:px-8 flex flex-col items-center justify-center relative z-10">
        <div className="max-w-lg mx-auto space-y-3 text-center">
          <h3 className="text-4xl text-[#f4f3f3] font-extrabold mx-auto md:text-5xl">
            CONTACT
          </h3>
          <p className="text-[#f4f3f3] text-3xl font-semibold sm:text-4xl">
            Vous avez un projet ? Discutons-en !
          </p>
          <p className="text-[#f4f3f3] text-2xl sm:text-2xl">
            +33 (0)6 27 23 59 76
          </p>
          <p className="text-[#f4f3f3] text-2xl sm:text-2xl">
            atelierdecember@gmail.com
          </p>
          <div className="py-10 max-w-lg mx-auto">
            <a href="https://calendly.com/atelierdecember/appel-de-decouverte-pour-la-creation-de-site-web" target="_blank" rel="noopener noreferrer">
            <button className="px-3 py-3 rounded-lg w-full font-extrabold text-sm duration-150 text-[#f4f3f3] bg-custom-gradient hover:px-6 shadow-card">                Je réserve un appel de découverte gratuit
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className="absolute inset-0 m-auto max-w-xs h-[357px] blur-[118px] sm:max-w-md md:max-w-lg pointer-events-none" style={{ background: "linear-gradient(106.89deg, rgba(255, 255, 255, 0.2) 0%, rgba(192, 192, 192, 0.3) 100%)" }}></div>
    </section>
  );
}

export default Contact;
