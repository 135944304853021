import React from 'react';
import Works from '../Works/Works';
import Pricing from '../Pricing/Pricing';
import About from '../About/About';
import Contact from '../Contact/Contact';
import Footer from '../../components/Footer/Footer';
import WelcomeBanner from '../../components/WelcomeBanner/WelcomeBanner';
import Header from '../../components/AllHeader/Header/Header';
import InfiniteMovingCards from '../../components/InfiniteMovingCards/InfiniteMovingCards';
import ScrollingBanner from '../../components/BandeauPromo/ScrollingBanner'
import './Home.css'


function Home() {
  return (
    <div className="Home">
      <Header/>
      <ScrollingBanner/>
      <WelcomeBanner/>
      <About />
      <InfiniteMovingCards/>
      <Works />
      <Pricing  />
      <Contact  />
      <Footer />
    </div>
  );
}

export default Home;