import React from 'react';
import Logo from '../../../img/LogoSvg.svg';
import MenuBanner from '../../MenuBanner/MenuBanner.jsx';
import Burger from '../Burger/Burger.jsx';
import './Header.css';
import { NavLink } from 'react-router-dom';

function Header({ showWelcomeBanner }) {

  return (
    <div className="Header">
      <div className="HeaderContent">
        <NavLink to="/">
          <img src={Logo} width={220} height={80} alt="logo AD" />
        </NavLink>
        <Burger />
      </div>
      {showWelcomeBanner ? '' : <MenuBanner />}
    </div>
  );
}

export default Header;
