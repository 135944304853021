import React from 'react';
import './Works.css';
import GalleryCards from '../../components/GalleryProject/GalleryCardProject';

function Works() {
  return (
    <div id="works" className="Works">
    <GalleryCards />
    </div>
  );
}

export default Works;