import React from "react";
import Eddy from '../../img/IMG_0820.png'
import Nina from '../../img/nina-profil.webp'
import Sophie from '../../img/Sophie.png'
import AntreConnaissance from '../../img/logoNav.webp'
import Amine from '../../img/amine.jpg'
import "./InfiniteMovingCards.css";

function InfiniteMovingCards() {
    const testimonials = [
        {
            avatar: Eddy,
            name: "Eddy Cherif",
            title: "Praticien médecine chinoise",
            quote: "J'ai eu l'occasion de faire mon premier site pro avec l'atelier december, l'accompagnement est top et le résultat correspond parfaitement à mes attentes ; merci encore !"
        },
        {
            avatar: Nina,
            name: "Nina Carducci",
            title: "Photographe",
            quote: "Un travail de SEO et retouche design au top. Des délais respectés et un service client impeccable. Notre site web attire désormais beaucoup plus de visiteurs."
        },
        {
            avatar: Sophie,
            name: "Sophie Bluel",
            title: "Architecte",
            quote: "Une collaboration fluide et professionnelle du début à la fin. Le rendu dépasse mes attentes."
        },
        {
            avatar: AntreConnaissance,
            name: "Antre-connaissance",
            title: "Association",
            quote: "Nous voulions un site avec une identité forte, qui reflète l'identité de l'association. Après quelques aller-retours pour peaufiner les details, nous sommes aujourd'hui fier du résultat. Merci à vous."
        },
        {
            avatar: Amine,
            name: "Tonton Tajwid",
            title: "Professeur particulier",
            quote: "J'avais besoin d'une page vitrine pour présenter mes cours de manière simple et clair, le travail à été rapide et conforme à mes demandes. Je suis satisfait et je recommande."
        },
   
    ];

    return (
        <section className="bg-[#111] py-14">
        <div className="max-w-screen-xl mx-auto px-4 md:px-8">
            <div className="max-w-xl sm:text-center md:mx-auto">
                <h3 className="text-white text-3xl font-semibold sm:text-4xl">
                    Nos clients en parlent le mieux
                </h3>
            </div>
            <div className="scroller mt-12">
                <ul className="flex">
                    {[...testimonials, ...testimonials].map((item, idx) => (
                        <li
                        key={idx}
                        className="bg-custom-gradient p-4 rounded-xl testimonial-card flex-shrink-0"
                      >
                            <figure>
                                <div className="flex items-center gap-x-4">
                                    <img src={item.avatar} alt={item.name} className="w-16 h-16 rounded-full border-2" />
                                    <div>
                                        <span className="block text-white font-semibold">{item.name}</span>
                                        <span className="block text-gray-400 text-sm mt-0.5">{item.title}</span>
                                    </div>
                                </div>
                                <blockquote>
                                    <p className="mt-6 text-gray-300">
                                        {item.quote}
                                    </p>
                                </blockquote>
                            </figure>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    </section>
);
}


export default InfiniteMovingCards;